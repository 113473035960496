import React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import Icon from '../components/iconfontCpt'
import '../style/mogeaver.scoped.scss'
import { Link } from "gatsby"

const Mogeaver = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true}>
      <Seo title="MogDB: Mogeaver"/>
      <div>
        <div className="header-placeholder"></div>
        <div className="mogeaver-banner">
          <div className="product-banner-box black-thema">
            <h1 className="product-banner-title"><FormattedMessage id="mogeaver.pageTitle" /><i className="mogeaver"></i></h1>
            <div className="product-banner-desc">
              <p><FormattedMessage id="mogeaver.pageContent" /></p>
            </div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/mogeaver/all/`} className="btn"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/mogeaver-overview`} target="_blank" className="btn"><FormattedMessage id="index.docs" /></a>
            </div>
          </div>
        </div>
        <div className="mogeaver-feature">
          <div className="feature-title"><FormattedMessage id="mogeaver.mogeaverFeatureTitle" /></div>
          <div className="feature-box">
            <div className="feature-item one">
              <div className="feature-icon">
                <Icon type="icon-multiplatform" size={70}/>
              </div>
              <div className="title"><FormattedMessage id="mogeaver.featureTitle1" /></div>
              <div className="content">
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes1-1" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes1-2" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes1-3" /></span></div>
              </div>
            </div>
            <div className="feature-item two">
              <div className="feature-icon">
                <Icon type="icon-compatibility" size={70}/>
              </div>
              <div className="title"><FormattedMessage id="mogeaver.featureTitle2" /></div>
              <div className="content">
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-1" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-2" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-3" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-4" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-5" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes2-6" /></span></div>
              </div>
            </div>
            <div className="feature-item three">
              <div className="feature-icon">
                <Icon type="icon-graphical" size={70}/>
              </div>
              <div className="title"><FormattedMessage id="mogeaver.featureTitle3" /></div>
              <div className="content">
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes3-1" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes3-2" /></span></div>
                <div><i></i><span><FormattedMessage id="mogeaver.featureDes3-3" /></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="experience">
          <div>
            <div className="e-title"><FormattedMessage id="index.experience" /></div>
            <div className="e-content"><FormattedMessage id="mogeaver.experienceContent" /></div>
            <div className="btn-group">
              <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/mogeaver-release-notes`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
              <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Mogeaver